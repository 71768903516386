export const getData = (key) => {
  return window.localStorage.getItem(key);
};

export const saveData = (key, data) => {
  window.localStorage.setItem(key, data);
};

export const destroyData = (key) => {
  window.localStorage.removeItem(key);
};

export const checkPermission = (condition) => {
  const features = window.localStorage.getItem('listFeature');
  let listFeature = [];
  if (features) {
    listFeature = features.split(',');
  }
  const isShow = listFeature.some((f) => condition.indexOf(f) >= 0);
  return isShow;
};

export const checkPermissions = (conditions) => {
  const features = window.localStorage.getItem('listFeature');
  let listFeature = [];
  if (features) {
    listFeature = features.split(',');
  }
  const isShow = listFeature.some((f) => conditions.indexOf(f) >= 0);
  return isShow;
};

export default {
  getData,
  saveData,
  destroyData,
  checkPermission,
  checkPermissions,
};
